import React, { useEffect, useRef, useState } from "react";
// import './UserDataSection.css'
import "../Sidebar/Sidebar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Fade } from "react-reveal";
import { fetchData, formatDateString, postData } from "../../Api/Clientfunctions";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Notification = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  console.log(formatDateString(startDate));
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [betData, setBetData] = useState([]);
  const [totalPages, setTotalPage] = useState(1);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState({title: '',body: ''});

  useEffect(() => {
    getBetData();
  }, [currentPage]);

  async function getBetData() {
    try {
      const res = await fetchData(
        `/admin/fcm/getAllNotification?page=${currentPage}&limit=10`
      );
      if (res.status) {
        setBetData(res.data);
        setTotalPage(Math.ceil(res.length / 10));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const filteredData = betData.filter(
    (row) =>
      (row.title &&
        row?.title
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (row.body &&
        row?.body
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (row.userCount &&
        row?.userCount
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (row.id &&
        row?.id.toString().toLowerCase().includes(searchTerm.toLowerCase()))
  );
  const dateFilterData = filteredData.filter((item) => {
    // Check if startDate and endDate are valid dates
    if (!startDate || !endDate) {
      return true; // If startDate or endDate is not provided, include the item
    }
    const createdAt = new Date(item.createdAt);
    return createdAt >= startDate && createdAt <= endDate;
  });

  const finalData = dateFilterData.length ? dateFilterData : filteredData;

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#myTable" }); // Assuming your table has an id 'myTable'
    doc.save("table_data.pdf");
  };

  const handlePrint = () => {
    window.print();
  };

  const tableRef = useRef(null);

  const copyTable = () => {
    const range = document.createRange();
    range.selectNode(tableRef.current);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    //   alert('Table copied to clipboard!');
  };

  const downloadTableAsCSV = () => {
    const table = document.getElementById("myTable"); // Assuming your table has an id 'myTable'

    if (!table) {
      console.error("Table not found");
      return;
    }

    const rows = table.querySelectorAll("tr");
    const csvData = [];

    rows.forEach((row) => {
      const rowData = [];
      const cells = row.querySelectorAll("td, th");

      cells.forEach((cell) => {
        rowData.push(cell.innerText);
      });

      csvData.push(rowData.join(","));
    });

    const csvContent = csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0])
  };

  const sendNotification = async(e) => {
    e.preventDefault();
    const newErrors = {
      title: title ? '' : 'Title is required',
      body: body ? '' : 'Body is required',
    };
    if (newErrors.title || newErrors.body ) {
      setErrors(newErrors);
      return;
    }
    const data = new FormData();
    data.append('title', title);
    data.append('body', body);
    data.append('notificationImage', image);
    const res = await postData(`/admin/fcm/pushNotification`, data);
    if (res.status || res.message) {
      setTitle('');
      setBody('');
      setImage('');
      setErrors({title:"",body:""})
      document.getElementById('formFile').value='';
      Swal.fire(res.message);
      getBetData()
    }else{
      setTitle('');
      setBody('');
      setImage('');
      setErrors({title:"",body:""})
      Swal.fire(res.message);
    }
  }

  return (
    <>
      <Fade top distance="2%" duration={700}>
        <div className="wrapper">
          {/*page-wrapper*/}
          <div className="page-wrapper">
            {/*page-content-wrapper*/}
            <div className="page-content-wrapper">
              <div className="page-content">
                {/*breadcrumb*/}
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3 justify-content-sm-between">
                  <div className="breadcrumb-title pe-3">Notification</div>
                  {/* <button className="btn btn-success">Send Notification</button> */}
                </div>
                {/*end breadcrumb*/}
                <div className="card radius-15">
                  <div className="card-body">
                    <div className="card-title">
                      <h4 className="mb-0">Notification Send</h4>
                        <form className="mt-2" onSubmit={sendNotification}>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label for="title">Title</label>
                                  <input type="text" className="form-control" id="title" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                  {errors.title && <small className="text-danger">{errors.title}</small>}
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label for="body">Body</label>
                                  <textarea className="form-control" id="body" rows="2" value={body} onChange={(e) => setBody(e.target.value)}></textarea>
                                  {errors.body && <small className="text-danger">{errors.body}</small>}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                <div class="mb-3">
                                  <label for="formFile" class="form-label">Image</label>
                                  <input class="form-control" type="file" id="formFile" onChange={handleFileChange}/>
                                </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary m-2">Send Notification</button>
                          </form>
                      </div>
                    </div>
                  </div>
                <div className="card">      
                  <div className="cards-body">
                    <div
                      className="card-title"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h4 className="mb-0">Notification</h4>
                    </div>

                    {/*  */}
                    <div
                      className="search-and-button d-flex mt-4"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="buttons">
                        <button onClick={copyTable}>Copy</button>
                        <button onClick={downloadTableAsCSV}>CSV</button>
                        <button onClick={downloadTableAsCSV}>Excel</button>
                        <button onClick={generatePDF}>PDF</button>
                        <button onClick={handlePrint}>Print</button>
                      </div>
                      <div>
                        Search:-
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </div>
                    {/*  */}

                    <hr />
                    <div className="table-responsive">
                      <div className="card">
                        <div className="cards-body">
                          <div className="card-title">
                            <h4 className="mb-0">Notification History</h4>
                          </div>
                          <div
                            className="d-flex gap-2 justify-content-end mt-3"
                            style={{ marginRight: "24px" }}
                          >
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                            />
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                            />
                          </div>
                          <hr />
                          <div className="table-responsive">
                            <table
                              ref={tableRef}
                              id="myTable"
                              className="table table-striped table-bordered"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr
                                  style={{
                                    fontFamily: "ubuntu-medium,sans-serif",
                                  }}
                                >
                                  <th>ID</th>
                                  <th>Title</th>
                                  <th>Body</th>
                                  <th>Image</th>
                                  <th>Users</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody
                                style={{
                                  fontFamily: "ubuntu-medium,sans-serif",
                                }}
                              >
                                {finalData.map((row, index) => (
                                  <tr key={index}>
                                    <td>{row.id}</td>
                                    <td>{row.title}</td>
                                    <td>{row.body}</td>
                                    <td>
                                      {row.image != "" &&(
                                        <img src={process.env.REACT_APP_API_URL+row.image} height={50} />
                                      )}
                                    </td>
                                    <td>{row.userCount}</td>
                                    <td>
                                      {formatDateString(row.createdAt)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot></tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="paginations">
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <i className="bx bx-chevron-left"></i>
                          Previous
                        </button>
                        <div>{currentPage}</div>
                        <button onClick={() => paginate(currentPage + 1)}>
                          Next
                          <i className="bx bx-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end page-content-wrapper*/}
          </div>
          {/*end page-wrapper*/}
          {/*start overlay*/}
          <div className="overlay toggle-btn-mobile" />
          {/*end overlay*/}
          {/*Start Back To Top Button*/}
          <a href="#" className="back-to-top">
            <i className="bx bxs-up-arrow-alt" />
          </a>
          {/*End Back To Top Button*/}
        </div>
      </Fade>
    </>
  );
};

export default Notification;
