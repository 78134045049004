import React, { useState, useEffect } from "react";
import { Fade } from "react-reveal";
import "./Refer.css";
import { postData, fetchData, deleteData } from "../../Api/Clientfunctions";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import ExampleModal from "./model";
const Refer = () => {
  const [referData, setReferData] = useState({});
  const { data } = useSWR("/admin/getreferdetails", fetchData);
  const [formData, setFormData] = useState(referData);
  const [editBonusData, setEditBonusData] = useState({});

  const [modalIsOpen, setIsOpen] = React.useState(false);
  //console.log(referData);
  useEffect(() => {
    if (data && data.data) {
      setReferData(data.data);
      setFormData(referData);
    }
  }, [data, referData]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    // If the input is a file input, update the state with the file
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your logic for handling form submission
    //console.log("Form data submitted:", formData);
    const res = await postData("/admin/setrefer", formData);
    if (res.status) {
      //console.log(res.message);
      toast.success(res.message);
      Swal.fire("Wow..", res.message, "success");
    } else {
      toast.error("something went wrong!...");
      Swal.fire("Oops!..", "Something Went Wrong!..", "error");
    }
  };
  const [amount, setAmount] = useState("");
  const [bonus, setBonus] = useState("");

  const handleSubmit01 = async (event) => {
    event.preventDefault();
    const amountNum = parseInt(amount);
    const bonusNum = parseInt(bonus);
    const res = await postData("/admin/createbonus", {
      money: amountNum,
      bonus: bonusNum,
    });
    if (res.status || res.success) {
      mutate("/admin/getallbonus");
      toast.success(res.message);
      Swal.fire("Wow..", res.message, "success");
    } else {
      toast.error("something went wrong!...");
      Swal.fire("Oops!..", "Something Went Wrong!..", "error");
    }
  };
  const [allBonus, setAllBonus] = useState([]);
  const { data: adata } = useSWR("/admin/getallbonus", fetchData);
  useEffect(() => {
    if (adata && adata?.data) {
      setAllBonus(adata?.data || []);
    }
  }, [adata]);
  const handleDelete = async (id) => {
    const res = await deleteData("/admin/deletebonus", { id });
    if (res.status || res.success) {
      mutate("/admin/getallbonus");
      toast.success(res.message);
      Swal.fire("Wow..", res.message, "success");
    } else {
      toast.error("something went wrong!...");
      Swal.fire("Oops!..", "Something Went Wrong!..", "error");
    }
  };

  const handleChangeModel = (model, data) => {
    setIsOpen(model)
    setEditBonusData(data)
  };
  return (
    <Fade top distance="2%" duration={700}>
      <div style={{ background: "#F2EDF3" }} className="wrapper">
        {/*page-wrapper*/}
        <div className="page-wrapper">
          {/*page-content-wrapper*/}
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="Bank-detail">
                <div className="Bank-headings">
                  <i className="bx bxs-home"></i>
                  <h2> Admin Setup</h2>
                </div>
                <div className="d-flex">
                  <form onSubmit={handleSubmit}>
                    <div className="Bank-lable">
                      <label> Referrer Commission:</label>
                      <input
                        type="number"
                        id="parentCommission"
                        placeholder="(Person who shares account with referral id)"
                        name="parentCommission"
                        defaultValue={referData?.parentCommission}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="Bank-lable">
                      <label> Recipients Commission:</label>
                      <input
                        placeholder="(Person who creates account with referral id)"
                        type="number"
                        id="friendCommission"
                        name="friendCommission"
                        defaultValue={referData?.friendCommission}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="Bank-lable">
                      <label> User Bonus:</label>
                      <input
                        placeholder="(If user does not provide referral id)"
                        type="number"
                        id="notReferCommission"
                        name="notReferCommission"
                        defaultValue={referData?.notReferCommission}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="Bank-lable">
                      <label>Minimun Withdrawal Limit:</label>
                      <input
                        placeholder="(Minimun Withdrawal Limit)"
                        type="number"
                        id="mwa"
                        name="mwa"
                        defaultValue={referData?.mwa}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="Bank-lable">
                      <label> Withdraw Commission:</label>
                      <input
                        placeholder="(Withdraw Commission)"
                        type="number"
                        id="withdraw_commission"
                        name="withdraw_commission"
                        defaultValue={referData?.withdraw_commission}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <button type="submit">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="Bank-detail">
                <div className="Bank-headings  mt-4">
                  <i className="bx bxs-home"></i>
                  <h2> Bonus Setup</h2>
                </div>
                <div className="d-flex">
                  <form onSubmit={handleSubmit01}>
                    <div className="Bank-lable">
                      <label> Money:</label>

                      <input
                        type="number"
                        value={amount}
                        placeholder="Recharge Money"
                        onChange={(e) => setAmount(e.target.value)}
                        required
                      />
                    </div>

                    <div className="Bank-lable">
                      <label> Bonus:</label>

                      <input
                        placeholder="Bonus on Money"
                        required
                        type="number"
                        value={bonus}
                        onChange={(e) => setBonus(e.target.value)}
                      />
                    </div>
                    <div>
                      <button type="submit">Submit</button>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Money</th>
                          <th>Bonus (%)</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allBonus.map((item) => (
                          <tr key={item.id}>
                            <td>{item.money}</td>
                            <td>{item.bonus}</td>
                            <td>
                              <span
                                className="delete-icon cursor-pointer"
                                onClick={() => handleDelete(item.id)}
                              >
                                ❌
                              </span>
                              <i
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                    fontSize: "20px"
                                  }}
                                  className="bx bx-edit-alt"
                                  onClick={() => handleChangeModel(true, item)}
                                ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </form>
                  <ExampleModal setIsOpen={setIsOpen} modalIsOpen={modalIsOpen} editBonusData={editBonusData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Refer;
