import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { postData } from '../../Api/Clientfunctions';
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { mutate } from "swr";
import "./Refer.css";

// Modal.setAppElement('#root'); // Uncomment this line if you're using React 17 or below

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
//   Modal.setAppElement('#yourAppElement');

const ExampleModal = ({modalIsOpen, setIsOpen, editBonusData}) => {
    let subtitle;
    const [amount, setAmount] = useState("");
    const [bonus, setBonus] = useState("");
    const [id, setId] = useState("");
    
    useEffect(() => {
      console.log("editBonusData", editBonusData);
        setAmount(editBonusData.money);
        setBonus(editBonusData.bonus);
        setId(editBonusData.id);
    }, [modalIsOpen]);
  
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }

    const handleSubmit01 = async (event) => {
        event.preventDefault();
        const amountNum = parseInt(amount);
        const bonusNum = parseInt(bonus);
        const res = await postData("/admin/updateBonus", {
          id:id,
          money: amountNum,
          bonus: bonusNum,
        });
        if (res.status || res.success) {
          mutate("/admin/getallbonus");
          toast.success(res.message);
          Swal.fire("Wow..", res.message, "success");
          setIsOpen(false);
          setAmount("")
          setBonus("")
          setId("")
        } else {
          toast.error("something went wrong!...");
          Swal.fire("Oops!..", "Something Went Wrong!..", "error");
        }
      };
  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Edit</h2>
        <form onSubmit={handleSubmit01}>
        <div className="Bank-lable">
            <label> Money:</label>

            <input
            type="number"
            value={amount}
            placeholder="Recharge Money"
            onChange={(e) => setAmount(e.target.value)}
            required
            />
        </div>

        <div className="Bank-lable">
            <label> Bonus:</label>

            <input
            placeholder="Bonus on Money"
            required
            type="number"
            value={bonus}
            onChange={(e) => setBonus(e.target.value)}
            />
        </div>
        <div className='model-btn-div'>
          <button type="submit" className="btn btn-primary">Update</button>
          <button onClick={closeModal} type="button" className="btn btn-danger">Close</button>
        </div>
        </form>
      </Modal>
    </div>
  );
};

export default ExampleModal;
